import { Company } from '@prisma/client';

export interface LoggedInUser {
  email: string;
  userId: number;
  roles: string[];
  companyCode: string;
  company: Company;
  srCode: string;
  azureIdExists: boolean;
}
