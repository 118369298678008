import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { ROUTE_URLS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class DpsWorkOrderGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isDpsWorkOrderUser) {
      this.router.navigateByUrl(ROUTE_URLS.DPS_WORK_ORDER);
      return false;
    }
    return true;
  }
}
