import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ITelemetryPlugin,
} from '@microsoft/applicationinsights-web';

import { environment } from '@ers-cat-app/env/environment';

@Component({
  selector: 'ers-app-insights',
  template: '',
  styles: [],
  standalone: true,
})
export class AppInsightsComponent {
  constructor(private readonly router: Router) {
    const angularPlugin = new AngularPlugin() as unknown as ITelemetryPlugin;
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.azureInstrumentKey || '',
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
    appInsights.loadAppInsights();
  }
}
