export const COOLING_CALC_MATERIALS = {
  WALL_CEILING_MATERIAL_LIST: [
    // { ID: 0, MaterialType: 'Select', MaterialRValue: '0', MaterialUValue: 0 },
    {
      ID: 1,
      MaterialType: 'Tent',
      MaterialRValue: 0.5,
      MaterialUValue: 2,
    },
    {
      ID: 2,
      MaterialType: 'Brick-Uninsulated',
      MaterialRValue: 2.22,
      MaterialUValue: 0.45,
    },
    {
      ID: 3,
      MaterialType: 'Brick-Light insulated',
      MaterialRValue: 3.13,
      MaterialUValue: 0.32,
    },
    {
      ID: 4,
      MaterialType: 'Brick-Well Insulated',
      MaterialRValue: 5.0,
      MaterialUValue: 0.2,
    },
    {
      ID: 5,
      MaterialType: 'Wood-Uninsulated',
      MaterialRValue: 3.57,
      MaterialUValue: 0.28,
    },
    {
      ID: 6,
      MaterialType: 'Wood-Light Insulated',
      MaterialRValue: 7.14,
      MaterialUValue: 0.14,
    },
    {
      ID: 7,
      MaterialType: 'Wood-Well Insulated',
      MaterialRValue: 14.29,
      MaterialUValue: 0.07,
    },
    {
      ID: 8,
      MaterialType: 'Metal-Uninsulated',
      MaterialRValue: 0.71,
      MaterialUValue: 1.4,
    },
    {
      ID: 9,
      MaterialType: 'Metal-Light Insulated',
      MaterialRValue: 1.25,
      MaterialUValue: 0.8,
    },
    {
      ID: 10,
      MaterialType: 'Metal-Well Insulated',
      MaterialRValue: 2.86,
      MaterialUValue: 0.35,
    },
  ],
};
