import { formatNumber } from '@angular/common';

export function removeWordsFromFront(
  input: string,
  numberOfWordsToRemove: number,
) {
  const newString = input.replace(/\s+/g, ' ').trim();
  const newStringAsArrayOfWords = newString.split(' ');
  const newStringMinusWords = newStringAsArrayOfWords
    .slice(numberOfWordsToRemove, newStringAsArrayOfWords.length)
    .join(' ');

  return newStringMinusWords;
}

export const formatAllNumbersInString = (stringWithNumbers: string) => {
  const newString = stringWithNumbers.replace(/\s+/g, ' ').trim();
  const newStringAsArrayOfWords = newString.split(' ');
  const stringWithFormattedNumbers = newStringAsArrayOfWords
    .map((word: string) =>
      Number(word) ? formatNumber(Number(word), 'en') : word,
    )
    .join(' ');

  return stringWithFormattedNumbers;
};

// Transform snake_case and kebab-case and camelCase (separate with spaces)
export const splitSnakeKebabCamelCase = (str: string) =>
  str
    .replace(/-/g, ' ')
    .replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2');

export const camelize = (originalString: string) => {
  return originalString.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) {
      return '';
    }
    // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const isoDateWithoutTime = (isoDateString: string) =>
  isoDateString.split('T')[0];

// Removes problematic characters and spaces
// (from https://en.wikipedia.org/wiki/Filename#Reserved_characters_and_words)
export const removeInvalidFileNameChars = (fileName: string) =>
  fileName.replace(/[/\\?%*:|"<> ]/g, '');

export const addSpaceBetweenNumbersAndLetters = (inputString: string) => {
  return inputString
    .replace(/(\d)([a-zA-Z])/g, '$1 $2')
    .replace(/([a-zA-Z])(\d)/g, '$1 $2');
};
