import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { withCache } from '@ngneat/cashew';

import { environment } from '@ers-cat-app/env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StaticVars } from '@ers-cat-app/shared/models';
import { SubmitUserMessageRequest, BusinessCardRequest } from '@ers/shared';
import { STORAGE_KEYS } from '@ers-cat-app/shared/constants';

export enum THEME {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private readonly BASE_URL = `${environment.apiUrl}/system`;
  private readonly HEALTH_URL = `${environment.apiUrl}/health`;

  // getters and setters
  private _systemTheme: THEME = THEME.LIGHT;

  get isDarkMode(): boolean {
    return this.currentTheme === THEME.SYSTEM
      ? this.systemTheme === THEME.DARK
      : this.currentTheme === THEME.DARK;
  }

  get systemTheme(): THEME {
    return this._systemTheme;
  }

  set systemTheme(theme: THEME) {
    this._systemTheme = theme;
  }

  get currentTheme(): THEME {
    const currentThemeSaved = localStorage.getItem(STORAGE_KEYS.THEME);

    // If no theme is saved, default to system theme
    if (!currentThemeSaved || currentThemeSaved === 'null') {
      this.currentTheme = THEME.SYSTEM;
    }

    return currentThemeSaved as THEME;
  }

  set currentTheme(theme: THEME) {
    localStorage.setItem(STORAGE_KEYS.THEME, theme);

    this.applyDarkMode();
  }

  constructor(private readonly http: HttpClient) {
    this.setThemeOnLoad();
  }

  reloadRates(): Observable<any> {
    return this.http.get<any>(`${this.BASE_URL}/reloadRates`);
  }

  getStaticVars(): Observable<StaticVars> {
    return this.http.get<StaticVars>(`${this.BASE_URL}/static-vars`, {
      context: withCache(),
    });
  }

  setThemeOnLoad() {
    this.setSystemDefaultTheme();
    this.currentTheme =
      (localStorage.getItem(STORAGE_KEYS.THEME) as THEME) || THEME.SYSTEM;
  }

  applyDarkMode() {
    document.body.classList.toggle(THEME.DARK, this.isDarkMode);
  }

  submitBusinessCardRequest(
    request: BusinessCardRequest,
  ): Observable<HttpResponse<unknown>> {
    return this.http.post<HttpResponse<unknown>>(
      `${this.BASE_URL}/business-card-request`,
      request,
      {
        observe: 'response',
      },
    );
  }

  submitUserMessage(
    userEmail: string,
    messageType: 'feedback' | 'safety',
    request: SubmitUserMessageRequest,
  ): Observable<any> {
    return this.http.post<HttpResponse<unknown>>(
      `${this.BASE_URL}/feedback/${userEmail}/${messageType}`,
      request,
      {
        observe: 'response',
      },
    );
  }

  getApiHealth(): Observable<HealthCheckResponse> {
    return this.http.get<HealthCheckResponse>(`${this.HEALTH_URL}`);
  }

  private setSystemDefaultTheme() {
    const darkModeSetOnSystem = window.matchMedia(
      '(prefers-color-scheme: dark)',
    );

    const darkModeSetOnSystemValue = darkModeSetOnSystem.matches;
    const theme = darkModeSetOnSystemValue ? THEME.DARK : THEME.LIGHT;
    this.systemTheme = theme;

    // Listen for changes to the prefers-color-scheme media query (dark mode via user's system settings)
    darkModeSetOnSystem.addEventListener('change', mediaQuery => {
      const isDarkMode = mediaQuery.matches;
      this.systemTheme = isDarkMode ? THEME.DARK : THEME.LIGHT;

      if (this.currentTheme === THEME.SYSTEM) {
        this.applyDarkMode();
      }
    });
  }
}

export interface HealthCheckResponse {
  status: Status;
  info: {
    [name: string]: {
      [name: string]:
        | {
            status: Status;
          }
        | Status;
    };
  };
  error: {
    [name: string]: {
      status: Status;
      message: string;
    };
  };
  details: {
    [name: string]: {
      status: Status;
      message: string;
    };
  };
}

type Status = 'up' | 'down' | 'ok' | 'unknown';
