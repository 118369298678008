import { BOOL_LETTER_STRING } from '../../models';
import { Details } from './details.interface';
import { Note } from './note.interface';

export interface SubmitDpsWorkOrderRequest {
  userEmail: string;
  operatorCode: string;
  contract: string | null;
  customer: string | null;
  details: Details[];
  isMetered: BOOL_LETTER_STRING.Y | BOOL_LETTER_STRING.N;
  meter: number | null;
  notes: Note[];
  product: string;
  productDesc: string;
  serviceDate: string;
}
