export interface Equipment {
  id: string;
  name: string;
  shortName?: string;
  children?: Equipment[];
}

//TODO: Change IDs
const POWER_GEN_DIESEL_GENERATORS = {
  id: '1.1',
  name: 'Diesel Generators',
  children: [
    {
      id: '1.1.1',
      name: '20 kW',
      children: [],
    },
    {
      id: '1.1.2',
      name: '30 kW',
      children: [],
    },
    {
      id: '1.1.3',
      name: '50 kW',
      children: [],
    },
    {
      id: '1.1.4',
      name: '60 kW',
      children: [],
    },
    {
      id: '1.1.5',
      name: '100 kW',
      children: [],
    },
    {
      id: '1.1.6',
      name: '125 kW',
      children: [],
    },
    {
      id: '1.1.7',
      name: '200 kW',
      children: [],
    },
    {
      id: '1.1.8',
      name: '230 kW',
      children: [],
    },
    {
      id: '1.1.9',
      name: '300 kW',
      children: [],
    },
    {
      id: '1.1.10',
      name: '350 kW',
      children: [],
    },
    {
      id: '1.1.11',
      name: '400 kW',
      children: [],
    },
    {
      id: '1.1.12',
      name: '425 kW',
      children: [],
    },
  ],
};

const POWER_GEN_ACCESSORIES = {
  id: '2',
  name: 'Accessories',
  children: [
    {
      id: '2.1',
      name: 'Fuel Tanks',
      children: [
        {
          id: '2.2',
          name: '550 Gallon Fuel Tank',
          shortName: '500 Gallon',
        },
        {
          id: '2.3',
          name: '1000 Gallon Fuel Tank',
          shortName: '1000 Gallon',
        },
      ],
    },
  ],
};

const TEMP_CONTROL_ACCESSORIES = {
  id: '3',
  name: 'Accessories',
  children: [
    {
      id: '3.1',
      name: 'Pumps',
      children: [
        {
          id: '3.2',
          name: '250 GPM',
          shortName: '250 GPM',
        },
        {
          id: '3.3',
          name: '500 GPM',
          shortName: '500 GPM',
        },
        {
          id: '3.4',
          name: '1000 GPM',
          shortName: '1000 GPM',
        },
      ],
    },
  ],
};

export const EQUIPMENT_LIST: Equipment[] = [
  {
    id: '1',
    name: 'Power Generation',
    children: [POWER_GEN_DIESEL_GENERATORS, POWER_GEN_ACCESSORIES],
  },
  {
    id: '6',
    name: 'Temperature Control',
    children: [TEMP_CONTROL_ACCESSORIES],
  },
  {
    id: '12',
    name: 'Air Compressors',
  },
  {
    id: '13',
    name: 'Electrical Distribution',
  },
];
