import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@ers-cat-app/env/environment';
import { Equipment } from '@ers-cat-app/shared/models';
import { EquipmentOptionDto } from '@ers/shared';

@Injectable({
  providedIn: 'root',
})
export class SpecificationService {
  private readonly BASE_URL = `${environment.apiUrl}/specification`;

  constructor(private readonly http: HttpClient) {}

  getSubCat(id: string): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(`${this.BASE_URL}/sub/${id}`);
  }

  getItemInSubCat(subCatId: string, itemId: string): Observable<Equipment> {
    return this.http.get<Equipment>(
      `${this.BASE_URL}/sub/${subCatId}/item/${itemId}`,
    );
  }

  getOptionsOfSubCat(subCatId: string): Observable<EquipmentOptionDto[]> {
    return this.http.get<EquipmentOptionDto[]>(
      `${this.BASE_URL}/options/${subCatId}`,
    );
  }
}
