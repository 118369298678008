import { Role, User, Company } from '@prisma/client';

export type UserWithRoles = User & {
  roles: {
    role: Role & {
      company: Company;
    };
  }[];
} & {
  company: Company;
};
