import { IncidentType } from '@ers-cat-app/pages/safety/safety-incident-report/models/logic-app.model';
import { Details, RepairCode } from '@ers/shared';

export interface StaticVars {
  safetyIncidentReport: {
    incidentTypes: IncidentType[];
    internalLocations: InternalLocation[];
  };

  onDemandDocuments: {
    queryParameters: {
      objects: string[]; //['INVOICES', 'CONTRACTS'];
      statuses: string[]; //['OPEN', 'CLOSED', 'ANY'];
    };
  };

  pickOrder: {
    divisions: Division[];
    filterType: string[]; // ['PRODUCT', 'DESCRIPTION', 'ALL'];
    pickStatus: string[]; // ['UNPICKED', 'PICKED'];
  };

  purchaseOrder: {
    locations: {
      loc: string;
      comp: string;
      code: string;
      email: string;
    }[];
  };

  dpsWorkOrder: {
    repairCodes: RepairCode[];
    details: Details[];
  };
}

export interface Division {
  code: string;
  name: string;
  email: string;
  addr: string;
  city: string;
  state: string;
  zip: string;
}

export interface InternalLocation {
  division: string;
}
