import { environment } from '@ers-cat-app/env/environment';

export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  LOGGED_IN: 'logged_in',
  TOKEN_EXPIRATION: 'token_expiration',
  USER_EMAIL: 'user_email',
  EQUIPMENT_CACHE: `@cache-${environment.apiUrl}/equipment/menu`,
  STATIC_VARS_CACHE: `@cache-${environment.apiUrl}/system/static-vars`,
  AZURE_LOGIN: 'azure_login',
  IS_LOGGING_IN: 'is_logging_in',
  THEME: 'theme',
};

export const NAV_PARAMS_STORAGE_KEYS = {
  INVOICE: 'invoice',
  CONTRACT: 'contract',
  UPLIFT: 'uplift',
};
