/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, catchError, of, throwError } from 'rxjs';

import { STORAGE_KEYS } from '../constants/storage-keys';
import { AuthService } from '../services/auth/auth.service';
import { ROUTE_URLS } from '../constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  /**
   * Handles adding auth token to each HTTP request
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Ignore auth requests and don't pass auth tokens to the proxy for logic apps
    if (
      req.url.includes('/auth/')
      // || req.url.includes('/proxy')
    ) {
      return next.handle(req);
    }

    const accessToken: string =
      localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ||
      sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ||
      '';

    const tokenExpirationEpoch =
      localStorage.getItem(STORAGE_KEYS.TOKEN_EXPIRATION) ||
      sessionStorage.getItem(STORAGE_KEYS.TOKEN_EXPIRATION);

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (Date.now() > Number(tokenExpirationEpoch) * 1000) {
      this.authService.logout();
    }

    return next.handle(req).pipe(catchError(x => this.handleHttpError(x)));
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    // Log out if we detect a 401 or 403
    if (err.status === 401 || err.status === 403) {
      console.error('Authentication error detected.');

      this.authService.logout();
      this.router.navigateByUrl(ROUTE_URLS.LOGIN);

      return of(err.message);
    }
    return throwError(() => new Error('HTTP error detected.'));
  }
}
