//import { EnvName } from '@libs/enums/env.enum';

export const environment = {
  production: true,
  apiUrl: '/api',
  //env: EnvName.PROD,
  minPasswordLength: 12,
  appName: 'POWER+',
  azureClientId: '31ab358b-236d-48e8-8908-e615f3acc9d2',
  azureTenantId: '8dfe4506-fdae-4f04-839a-3adf8752eabb',
  azureAdEndpoint: 'https://login.microsoftonline.com/',
  ersDomainMatchString: 'ers-cat|black-hill|ers|localhost',
  weatherApiKey: '6d497680b0c0478f8b8214420232405',
  supportEmail: 'business.services@prshold.com',
  azureInstrumentKey: '96926285-826e-4f30-b2cf-5b0b102cb4f8',


};
