// https://github.com/koddr/a2hs.js/#config

import { environment } from '@ers-cat-app/env/environment';

const asset = '../../../assets/images/ERS_Power+_black.svg';
const brandName = environment.appName || 'POWER+';

const options = {
  brandName: brandName,
  logoImage: `<object data="${asset}" width="50" height="50" > </object>`, //inline SVG
  htmlContent: `Install <strong>${brandName}</strong> on your iOS device: tap share and <strong>Add to Home Screen</strong> ↓`,
};

export const a2hsOptions = Object.freeze(options);
