import { Document } from '../models';

export class OnDemandDocsInvoiceSearchRequest {
  contractNumber?: string;
  customerName?: string;
  documentStatus?: string;
  invoiceNumber?: string;
  poNumber?: string;
  productNumber?: string;
  quoteNumber?: string;
  repCode!: string;
}

export class OnDemandDocsContractSearchRequest {
  contractNumber?: string;
  customerName?: string;
  documentStatus?: string;
  poNumber?: string;
  productNumber?: string;
  quoteNumber?: string;
  repCode!: string;
}

export class SendDocumentsRequest {
  documents: Document[] | undefined;
  userMail: string | undefined;
}
