import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { withCache } from '@ngneat/cashew';

import { environment } from '@ers-cat-app/env/environment';
import { SalesRep } from '@ers-cat-app/shared/models';
import { DEFAULT_CACHE_TTL } from '@ers-cat-app/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class SalesHubService {
  private readonly BASE_URL = `${environment.apiUrl}/sales-hub`;

  constructor(private readonly http: HttpClient) {}

  get(): Observable<SalesRep> {
    return this.http.get<SalesRep>(`${this.BASE_URL}`);
  }

  getAll(): Observable<SalesRep[]> {
    return this.http.get<SalesRep[]>(`${this.BASE_URL}/all`);
  }

  listAll(): Observable<SalesRep[]> {
    return this.http.get<SalesRep[]>(`${this.BASE_URL}/list`, {
      context: withCache({ ttl: DEFAULT_CACHE_TTL }),
    });
  }

  getRollup(): Observable<SalesRep[]> {
    return this.http.get<SalesRep[]>(`${this.BASE_URL}/rollup/manager`);
  }
}
