import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@ers-cat-app/env/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly BASE_URL = `${environment.apiUrl}/report`;

  constructor(private readonly http: HttpClient) {}

  generateCalcCoolingReport(body: unknown): Observable<any> {
    return this.http.post(`${this.BASE_URL}/generate-cooling-report`, body, {
      responseType: 'blob' as 'json',
    });
  }
}
