import { PRODUCT_STATUS_CONTRACT } from '../proxy.model';
import { Division } from '../static-vars.model';
import { ProductContract } from './product-contract..model';

export interface SubmitUpliftRequest {
  items: Item[];
  photos: Image[];
  damaged_items: Item[];
  damage_photos: Image[];
  pickup_items: Item[];
  rr_returned_items: Item[];
  user_email: string;
  user_name: string;
  addl_location_email: string;

  location_details: Division;
  header: Header;
}

export interface Header extends ProductContract {
  COMM: string;
  DRIVER_NAME: string;
  FREIGHT_CO: string;
  HAS_ADD: boolean;
  HAS_DMG: boolean;
  HAS_PICKUP_ITEMS: boolean;
  HAS_RR: boolean;
  HAS_RR_RETURNED: boolean;
  IS_ADHOC: boolean;
  LOCATION: string;
  RETURN_DATE: string;
}

export interface Image {
  ImgContent: string;
  ImgName: string;
}

export interface Item {
  DEF_LVL: number;
  DMG_DESC: string;
  DMG_QTY: number;
  FUEL_LVL: number;
  HR_MTR: number;
  IS_ADD: boolean;
  IS_DMG: boolean;
  IS_QTY_VAR: boolean;
  ITEM_DETAILS_EXT: string;
  ITEM_DETAILS_INT: string;
  RR_UNIT: string;
  RR_VENDOR: string;
  RSCD_BULK: string;
  RSCD_DESC: string;
  RSCD_DETAIL_TRX_N: 3;
  RSCD_PROD_N: string;
  RSCD_QTY_IN: number;
  RSCD_QTY_OUT: number;
  RSCD_STATUS: typeof PRODUCT_STATUS_CONTRACT;
}
