import { AutoMap } from '@automapper/classes';

export class GetHierarchyDto {
  @AutoMap()
  id?: number | string;
  @AutoMap()
  parentId?: number;
  @AutoMap()
  name?: string;
  @AutoMap()
  parent?: string;
  @AutoMap()
  priority?: string;
  @AutoMap()
  visible?: string;
}
