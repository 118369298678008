export interface SharepointItem {
  '@microsoft.graph.downloadUrl'?: string;
  '@odata.nextLink'?: string;
  id: string;
  name: string;
  createdDateTime?: string;
  eTag?: string;
  lastModifiedDateTime?: string;
  webUr?: string;
  cTag?: string;
  size?: number;
  createdBy?: {
    user: {
      email: string;
      id: string;
      displayName: string;
    };
  };
  lastModifiedBy?: {
    user: {
      email: string;
      id: string;
      displayName: string;
    };
  };
  parentReference?: {
    driveType: string;
    driveId: string;
    id: string;
    name: string;
    path: string;
  };
  file?: {
    mimeType: string;
    hashes: {
      quickXorHash: string;
    };
  };
  fileSystemInfo?: {
    createdDateTime: string;
    lastModifiedDateTime: string;
  };
  shared?: {
    scope: string;
  };
  'listItem@odata.context'?: string;
  thumbnails?: {
    id: string;
    large: { height: number; width: number; url: string };
    medium: { height: number; width: number; url: string };
    small: { height: number; width: number; url: string };
  }[];
  listItem?: {
    '@odata.etag': string;
    createdDateTime: string;
    eTag: string;
    id: string;
    lastModifiedDateTime: string;
    webUrl: string;
    createdBy?: {
      user: {
        email: string;
        id: string;
        displayName: string;
      };
    };
    lastModifiedBy?: {
      user: {
        email: string;
        id: string;
        displayName: string;
      };
    };
    parentReference?: {
      id: string;
      siteId: string;
    };
    contentType?: {
      id: string;
      name: string;
    };
    'fields@odata.context'?: string;
    fields?: {
      '@odata.etag'?: string;
      FileLeafRef?: string;
      _dlc_DocIdUrl?: {
        Description?: string;
        Url?: string;
      };
      EditorLookupId?: string;
      DisplayName?: string;
      id?: string;
      ContentType?: string;
      Created?: string;
      AuthorLookupId?: string;
      Modified?: string;
      _CheckinComment?: string;
      LinkFilenameNoMenu?: string;
      LinkFilename?: string;
      DocIcon?: string;
      FileSizeDisplay?: string;
      ItemChildCount?: string;
      FolderChildCount?: string;
      _ComplianceFlags?: string;
      _ComplianceTag?: string;
      _ComplianceTagWrittenTime?: string;
      _ComplianceTagUserId?: string;
      _CommentCount?: string;
      _LikeCount?: string;
      _DisplayName?: string;
      Edit?: string;
      _UIVersionString?: string;
      ParentVersionStringLookupId?: string;
      ParentLeafNameLookupId?: string;
    };
  };
}
