export type AdUserWithManager = {
  department: string;
  displayName: string;
  id: string;
  jobTitle: string;
  manager: AdManager;
  givenName: string;
  surname: string;
};

export type AdUserWithDepartment = {
  department: string;
  displayName: string;
  id: string;
  jobTitle: string;
  givenName: string;
  surname: string;
};

export type AdManager = {
  displayName: string;
  id: string;
};
