export interface SalesRep {
  id: number;
  srid: string;
  name: string;
  monthTDRevenue: number;
  pMTDRevenue: number;
  deltaMTDRevenue: number;
  yearTDRevenue: number;
  pYTDRevenue: number;
  deltaYTDRevenue: number;
  AR0: number;
  AR30: number;
  AR60: number;
  AR90: number;
  AROver90: number;
  monthTDContracts: number;
  pMTDContracts: number;
  deltaMTDContracts: number;
  yearTDContracts: number;
  pYTDContracts: number;
  deltaYTDContracts: number;
  monthTDRevRank: number;
  yearTDRevRank: number;
  monthTDContractRank: number;
  yearTDContractRank: number;
  createdAt: Date;
  yearTDRentAmt: number;
  yearTDFuelAmt: number;
  yearTDLabrAmt: number;
  yearTDEnvrAmt: number;
  yearTDSaleAmt: number;
  yearTDTotalAmt: number;
  yearTDPaidAmt: number;
  yearTDAvgDtp: number;
  monthTDRentAmt: number;
  monthTDFuelAmt: number;
  monthTDLabrAmt: number;
  monthTDEnvrAmt: number;
  monthTDSaleAmt: number;
  monthTDTotalAmt: number;
  monthTDPaidAmt: number;
  monthTDAvgDtp: number;
  srCode?: string;
}
