import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { map, Observable } from 'rxjs';
import { ROUTE_URLS } from '@ers-cat-app/shared/constants';
import { AuthService } from '@ers-cat-app/shared/services/auth/auth.service';
import { Role } from '@ers/shared';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isLoggedIn$.pipe(
      map((isLoggedIn: boolean) => {
        // If not logged in
        if (!isLoggedIn) {
          this.router.navigateByUrl(ROUTE_URLS.LOGIN);
          return false;
        }

        return (
          this.authorizedRoles(route) &&
          this.blacklistedRoles(route) &&
          this.blacklistedCondition(route)
        );
      }),
    );
  }

  private authorizedRoles(route: ActivatedRouteSnapshot) {
    // Check User Role
    // How to implement:
    //    In file app-routing.module.ts,
    //    add '{ data: { authorizedRoles: ['EXAMPLE_ROLE'] } }'
    //    to routes that require special roles
    //
    //    i.e.
    //    const routes: Routes = [ {
    //      path: 'calculators',
    //      canActivate: [AuthGuard],
    //      data: { authorizedRoles: ['EXAMPLE_ROLE'] }
    //    } ]
    const requiresRoleToAuthorize = route.data['authorizedRoles'];

    // Found in app-routing.module.ts routes as '{ data: { authorizedRoles: ['EXAMPLE_ROLE'] } }'
    const authorizedRoles: Role[] = route.data['authorizedRoles']?.map(
      (role: Role) => role.toUpperCase(),
    );

    if (
      requiresRoleToAuthorize &&
      !this.authService.userHasRoleGivenListOfRoles(authorizedRoles)
    ) {
      this.router.navigateByUrl(ROUTE_URLS.HOME);
      return false;
    }

    return true;
  }

  /**
   * @deprecated Roles refactored to be role-specific (whitelist)
   */
  private blacklistedRoles(route: ActivatedRouteSnapshot) {
    // Check User Role
    // How to implement:
    //    In file app-routing.module.ts,
    //    add '{ data: { blacklistedRoles: ['EXAMPLE_ROLE'] } }'
    //    to routes that allow all roles to access except specific roles
    //
    //    i.e.
    //    const routes: Routes = [ {
    //      path: 'calculators',
    //      canActivate: [AuthGuard],
    //      data: { blacklistedRoles: ['EXAMPLE_ROLE'] }
    //    } ]
    const blacklistedRolesExist = route.data['blacklistedRoles'];

    // Found in app-routing.module.ts routes as '{ data: { blacklistedRoles: ['EXAMPLE_ROLE'] } }'
    const blacklistedRoles: Role[] = route.data['blacklistedRoles']?.map(
      (role: Role) => role.toUpperCase(),
    );

    if (
      blacklistedRolesExist &&
      this.authService.userHasRoleGivenListOfRoles(blacklistedRoles)
    ) {
      this.router.navigateByUrl(ROUTE_URLS.HOME);
      return false;
    }

    return true;
  }

  private blacklistedCondition(route: ActivatedRouteSnapshot) {
    // How to implement:
    //    In file app-routing.module.ts,
    //    add '{ data: { blacklistedCondition: {isAzureDirectoryUser: true, otherConditionHere: true}} }'

    const blacklistedConditionNeeded = route.data['blacklistedCondition'];

    if (
      blacklistedConditionNeeded &&
      blacklistedConditionNeeded.azureDirectoryUser
    ) {
      // If azure user, disallow access (return false)
      return !this.authService.isAzureUser();
    }

    return true;
  }
}
