export interface File {
  link: string;
  mimeType: string;
  name: string;
  id: string;
}

export interface EquipmentDocument {
  id: number;
  name: string;
  '@odata.type': string;
}
