import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { UrlShortener } from '@prisma/client';

import { environment } from '@ers-cat-app/env/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlShortenerService {
  private readonly BASE_URL = `${environment.apiUrl}/link`;

  constructor(private readonly http: HttpClient) {}

  generateShortUrl(url: string): Observable<UrlShortener> {
    return this.createShortUrl(url, null);
  }

  generateFileShortUrl(
    filename: string,
    isSharePoint = false,
  ): Observable<UrlShortener> {
    return this.createShortUrl(null, filename, isSharePoint);
  }

  generateExcelDocForBusinessCards(): Observable<any> {
    return this.http.get<any>(`${this.BASE_URL}/generate/sheet`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  /**
   * Generate a short url
   * @param url The url to shorten
   * @param filename The filename to shorten
   * @returns The shortened url
   *
   * @example
   * createShortUrl('https://www.google.com', 'test.pdf');
   *
   */
  private createShortUrl(
    url: string | null,
    filename?: string | null,
    isSharePoint: boolean = false,
  ): Observable<UrlShortener> {
    return this.http.post<UrlShortener>(`${this.BASE_URL}`, {
      url,
      filename,
      isSharePoint,
    });
  }
}
