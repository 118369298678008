import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { map, Observable } from 'rxjs';
import { ROUTE_URLS } from '@ers-cat-app/shared/constants';
import { AuthService } from '@ers-cat-app/shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isLoggedIn$.pipe(
      map((isLoggedIn: boolean) => {
        // If user is logged in
        if (isLoggedIn) {
          this.router.navigateByUrl(ROUTE_URLS.HOME);
          return false;
        }

        return true;
      }),
    );
  }
}
