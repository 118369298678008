import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalService,
} from '@azure/msal-angular';
import {
  LogLevel,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { environment } from '@ers-cat-app/env/environment';
import { ROUTE_URLS, STORAGE_KEYS } from '@ers-cat-app/shared/constants';
import {
  Observable,
  Subject,
  filter,
  of,
  retry,
  switchMap,
  takeUntil,
  timeout,
} from 'rxjs';
import { Claim } from '../interfaces/claim.interface';
import { AuthService, LoginResponse } from '../auth.service';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

@Injectable({
  providedIn: 'root',
})
export class MsalAuthService implements OnDestroy {
  loginDisplay = false;
  dataSource: Claim[] = [];
  private readonly BASE_URL = `${environment.apiUrl}/auth`;
  private readonly _destroying$ = new Subject<void>();
  jwtHelper: JwtHelperService = new JwtHelperService();
  isAttemptingLogin = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    readonly msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {
    this.init();
  }

  init(): void {
    this.checkAndSetActiveAccount();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) =>
            status === InteractionStatus.None ||
            status === InteractionStatus.HandleRedirect,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
      )
      .subscribe(result => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  postLoginWithAzure() {
    const loggedIn = this.authService.isLoggedIn$.value;
    if (!loggedIn && this.isAttemptingLogin === false) {
      this.isAttemptingLogin = true;
      return this.http
        .post<any>(`${this.BASE_URL}/loginWithAzure`, {})
        .pipe(
          timeout(10000), // Limit time to 10sec
          retry(3), // Maximum 3 retries
          switchMap((response: LoginResponse): Observable<LoginResponse> => {
            this.authService.onLoginSuccess(response, true);
            return of(response);
          }),
        )
        .subscribe();
    }
    return null;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      activeAccount = accounts[0];
    }
    this.setLoginDisplay();
    if (activeAccount) {
      const loggedIn = this.authService.isLoggedIn$.value;
      localStorage.setItem(
        STORAGE_KEYS.TOKEN_EXPIRATION,
        activeAccount.idTokenClaims?.exp?.toString() || '',
      );
      if (!loggedIn) {
        localStorage.setItem(
          STORAGE_KEYS.ACCESS_TOKEN,
          activeAccount.idToken || '',
        );
        this.postLoginWithAzure();
      }
    }
  }

  async loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  async logout(popup?: boolean) {
    if (popup) {
      await this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      // this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  isLoggedIn() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    const list: Claim[] = new Array<Claim>();

    Object.keys(claims).forEach(function (k, v) {
      const c: Claim = { id: 0, claim: '', value: '' };
      c.id = v;
      c.claim = k;
      c.value = claims ? claims[k] : null;
      list.push(c);
    });
    this.dataSource = list;
  }
}
