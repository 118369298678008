import { Role } from '@ers/shared';
import { ROUTE_URLS } from './routes';

export const SITEMAP_ROUTE_OBJ = {
  ADMIN: {
    roles: [
      Role.ADMIN,
      Role.ADMIN_ROLES,
      Role.ADMIN_USERS,
      Role.ADMIN_SYSTEMS,
      Role.ADMIN_USERS_READ_ONLY,
    ],
    name: 'admin',
    url: ROUTE_URLS.ADMIN,
    show: true,
    description: 'The admin page, requires Admin roles',
    children: {
      ADMIN_USERS_LIST: {
        roles: [Role.ADMIN, Role.ADMIN_USERS, Role.ADMIN_USERS_READ_ONLY],
        name: 'users list',
        url: 'admin/users-list',
        show: true,
        description: 'The admin users list page, requires Admin roles',
      },
      ADMIN_ROLE_LIST: {
        roles: [Role.ADMIN, Role.ADMIN_ROLES],
        name: 'roles list',
        url: 'admin/roles-list',
        show: true,
        description: 'The admin roles list page, requires Admin roles',
      },
      ADMIN_USER_MANAGE: {
        roles: [Role.ADMIN, Role.ADMIN_USERS, Role.ADMIN_USERS_READ_ONLY],
        name: 'user manage',
        url: 'admin/user-manage',
        show: true,
        description: 'The admin user manage page, requires Admin roles',
      },
      ADMIN_ROLE_MANAGE: {
        roles: [Role.ADMIN, Role.ADMIN_ROLES],
        name: 'role manage',
        url: 'admin/role-manage',
        show: true,
        description: 'The admin role manage page, requires Admin roles',
      },
      ADMIN_SYSTEM: {
        roles: [Role.ADMIN, Role.ADMIN_SYSTEMS],
        name: 'system',
        url: ROUTE_URLS.ADMIN_SYSTEM,
        show: true,
        description: 'The admin system page, requires Admin roles',
      },
    },
  },
  ASSET_TRACKING: {
    name: 'asset tracking',
    url: ROUTE_URLS.ASSET_TRACKING,
    roles: [Role.ADMIN],
    show: true,
    description: 'The asset tracking page',
  },
  CREATE_ACCOUNT: {
    name: 'create account',
    url: ROUTE_URLS.CREATE_ACCOUNT,
    show: false,
    description: 'The create account page',
  },
  TRAINING: {
    name: 'Training',
    url: ROUTE_URLS.DOCUMENTS,
    show: true,
    description: 'The training documents page',
    extras: '/document',
    args: { path: 'PRS/Training' },
  },
  DOCUMENTS: {
    name: 'documents',
    url: ROUTE_URLS.DOCUMENTS,
    show: true,
    description: 'The documents page',
    children: {
      BROCHURES_AND_FLYERS: {
        name: 'brochures and flyers',
        url: ROUTE_URLS.BROCHURES_AND_FLYERS,
        show: false,
        description: 'The documents brochures and flyers page MVP Use only',
      },
      DOCUMENT_PAGE: {
        name: 'document',
        url: 'documents/document',
        show: false,
        description: 'The documents sub pages',
      },
    },
  },
  EQUIPMENT: {
    name: 'equipment',
    url: ROUTE_URLS.EQUIPMENT,
    show: true,
    description: 'The equipment page',
    children: {
      EQUIPMENT_ANALYSIS: {
        name: 'analysis',
        url: ROUTE_URLS.EQUIPMENT_ANALYSIS,
        show: false,
        description: 'The equipment analysis page',
      },
      EQUIPMENT_CLASS: {
        name: 'class',
        url: ROUTE_URLS.EQUIPMENT_CLASS,
        show: false,
        description: 'The equipment class page',
      },
    },
  },
  EQUIPMENT_DETAILS: {
    name: 'equipment details',
    url: ROUTE_URLS.EQUIPMENT_DETAILS,
    show: false,
    description: 'The equipment details page',
  },
  JOB_MONITORING: {
    name: 'job monitoring',
    url: 'job-monitoring',
    roles: [Role.ADMIN],
    show: true,
    description: 'The job monitoring page',
  },
  FILE_SHARE: {
    name: 'file share',
    url: ROUTE_URLS.FILE_SHARE,
    show: false,
    description: 'The file share page',
  },
  FORGOT_PASSWORD: {
    name: 'forgot password',
    url: ROUTE_URLS.FORGOT_PASSWORD,
    show: false,
    description: 'The forgot password page',
  },
  HOME: {
    name: "WATT'S NEW",
    url: ROUTE_URLS.HOME,
    show: true,
    description: 'The home page',
  },
  LOGIN: {
    name: 'login',
    url: ROUTE_URLS.LOGIN,
    show: false,
    description: 'The login page',
  },
  SAFETY: {
    name: 'safety',
    url: ROUTE_URLS.SAFETY,
    show: true,
    description: 'The safety page',
    children: {
      SAFETY_INCIDENT_REPORT: {
        roles: [Role.ADMIN, Role.SAFETY_INCIDENT_REPORT],
        name: 'safety incident report',
        url: ROUTE_URLS.SAFETY_SAFETY_INCIDENT_REPORT,
        show: true,
        description: 'The safety incident report page',
      },

      JOB_SAFETY_ANALYSIS: {
        name: 'job safety analysis',
        url: ROUTE_URLS.SAFETY_JOB_SAFETY_ANALYSIS,
        show: true,
        description: 'The job safety analysis page',
      },

      SAFETY_DOCUMENTS: {
        name: 'Safety Documents',
        url: ROUTE_URLS.DOCUMENTS,
        show: true,
        description: 'The safety documents page',
        extras: '/document',
        args: { path: 'PRS/Safety' },
      },

      CONTACT_HSE: {
        name: 'contact hse',
        url: ROUTE_URLS.SAFETY_CONTACT_HSE,
        show: true,
        description: 'The contact hse page',
      },
    },
  },
  SALES_HUB: {
    roles: [
      Role.ADMIN,
      Role.SALES_HUB,
      Role.SALES_HUB_ADMIN,
      Role.SALES_HUB_MANAGER,
    ],
    name: 'sales hub',
    url: ROUTE_URLS.SALES_HUB,
    show: true,
    description: 'The sales hub page',
  },
  TOOLS: {
    roles: [
      Role.ADMIN,
      Role.CALCULATORS,
      Role.ON_DEMAND_DOCS,
      Role.OFF_RENT_REQUEST,
      Role.UPLIFT,
    ],
    name: 'tools',
    url: ROUTE_URLS.TOOLS,
    show: true,
    description: 'The tools page',
    children: {
      TOOLS_CALCULATORS: {
        Roles: [Role.ADMIN, Role.CALCULATORS],
        name: 'calculators',
        url: ROUTE_URLS.TOOLS_CALCULATORS,
        show: true,
        description: 'The tools calculators page',
        children: {
          TOOLS_CALCULATOR_COOLING: {
            name: 'cooling',
            url: ROUTE_URLS.TOOLS_CALCULATOR_COOLING,
            show: true,
            description: 'The tools calculators cooling page',
            children: {
              TOOLS_CALCULATOR_COOLING_REPORT: {
                name: 'report',
                url: ROUTE_URLS.TOOLS_CALCULATOR_COOLING_REPORT,
                show: true,
                description: 'The tools calculators cooling report page',
              },
              TOOLS_CALCULATOR_COOLING_REPORT_DETAILS: {
                name: 'report details',
                url: ROUTE_URLS.TOOLS_CALCULATOR_COOLING_REPORT_DETAILS,
                show: true,
                description:
                  'The tools calculators cooling report details page',
              },
            },
          },
          TOOLS_CALCULATOR_DPS_AMP: {
            name: 'amps',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_AMP,
            show: true,
            description: 'The tools calculators dps amps page',
          },
          TOOLS_CALCULATOR_DPS_EFFICIENCY: {
            name: 'efficiency',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_EFFICIENCY,
            show: true,
            description: 'The tools calculators dps efficiency page',
          },
          TOOLS_CALCULATOR_DPS_FLOW_RATE: {
            name: 'flow rate',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_FLOW_RATE,
            show: true,
            description: 'The tools calculators dps flow rate page',
          },
          TOOLS_CALCULATOR_DPS_FUEL_CONSUMPTION: {
            name: 'fuel consumption',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_FUEL_CONSUMPTION,
            show: true,
            description: 'The tools calculators dps fuel consumption page',
          },
          TOOLS_CALCULATOR_DPS_FUEL_COST: {
            name: 'fuel cost',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_FUEL_COST,
            show: true,
            description: 'The tools calculators dps fuel cost page',
          },
          TOOLS_CALCULATOR_DPS_HP_KW: {
            name: 'hp kw',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_HP_KW,
            show: true,
            description: 'The tools calculators dps hp kw page',
          },
          TOOLS_CALCULATOR_DPS_KJ_BTU: {
            name: 'kj btu',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_KJ_BTU,
            show: true,
            description: 'The tools calculators dps kj btu page',
          },
          TOOLS_CALCULATOR_DPS_KVA: {
            name: 'kva',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_KVA,
            show: true,
            description: 'The tools calculators dps kva page',
          },
          TOOLS_CALCULATOR_DPS_KW: {
            name: 'kw',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_KW,
            show: true,
            description: 'The tools calculators dps kw page',
          },
          TOOLS_CALCULATOR_DPS_KW_HOUR_COST: {
            name: 'kw hour cost',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_KW_HOUR_COST,
            show: true,
            description: 'The tools calculators dps kw hour cost page',
          },
          TOOLS_CALCULATOR_DPS_MCF_CUBIC_HOUR: {
            name: 'mcf cubic hour',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_MCF_CUBIC_HOUR,
            show: true,
            description: 'The tools calculators dps mcf cubic hour page',
          },
          TOOLS_CALCULATOR_DPS_PROJECT_SIZING_ISO: {
            name: 'project sizing iso',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_PROJECT_SIZING_ISO,
            show: true,
            description: 'The tools calculators dps project sizing iso page',
          },
          TOOLS_CALCULATOR_DPS_PROJECT_SIZING_SITE_RATING: {
            name: 'project sizing site rating',
            url: ROUTE_URLS.TOOLS_CALCULATOR_DPS_PROJECT_SIZING_SITE_RATING,
            show: true,
            description:
              'The tools calculators dps project sizing site rating page',
          },
        },
        TOOLS_CALCULATOR_CONVERSION: {
          name: 'conversion',
          url: ROUTE_URLS.TOOLS_CALCULATOR_CONVERSION,
          show: true,
          description: 'The tools calculators conversion page',
        },
      },
      TOOLS_ON_DEMAND_DOCS: {
        roles: [Role.ADMIN, Role.ON_DEMAND_DOCS],
        name: 'on demand docs',
        url: ROUTE_URLS.TOOLS_ON_DEMAND_DOCS,
        show: true,
        description: 'The tools on demand docs page',
        children: {
          TOOLS_ON_DEMAND_DOCS_INVOICE: {
            name: 'invoice',
            url: 'tools/on-demand-docs/invoice',
            show: false,
            description: 'The tools on demand docs invoice page',
          },
          TOOLS_ON_DEMAND_DOCS_CONTRACT: {
            name: 'contract',
            url: 'tools/on-demand-docs/contract',
            show: false,
            description: 'The tools on demand docs contract page',
          },
        },
      },
      TOOLS_OFF_RENT_REQUESTS: {
        roles: [Role.ADMIN, Role.OFF_RENT_REQUEST],
        name: 'off rent requests',
        url: ROUTE_URLS.TOOLS_OFF_RENT_REQUESTS,
        show: true,
        description: 'The tools off rent requests page',
        children: {
          TOOLS_OFF_RENT_REQUESTS_CONTRACT: {
            name: 'off rent requests contract',
            url: 'tools/off-rent-requests/contract',
            show: false,
            description: 'The tools off rent requests contract page',
          },
        },
      },
      TOOLS_UPLIFT: {
        roles: [Role.ADMIN, Role.UPLIFT],
        name: 'uplift',
        url: ROUTE_URLS.TOOLS_UPLIFT,
        show: true,
        description: 'The tools uplift page',
        children: {
          TOOLS_UPLIFT_ITEMS_SELECTION: {
            name: 'uplift items selection',
            url: 'tools/uplift-items-selection',
            show: false,
            description: 'The tools uplift items selection page',
          },
        },
      },
    },
  },
  SETTINGS: {
    name: 'settings',
    url: 'settings',
    show: true,
    description: 'The settings page',
    children: {
      SETTINGS_CHANGE_PASSWORD: {
        name: 'change password',
        url: ROUTE_URLS.SETTINGS_CHANGE_PASSWORD,
        show: true,
        description: 'The change password page',
      },
      SETTINGS_HEALTH: {
        name: 'health',
        url: ROUTE_URLS.SETTINGS_HEALTH,
        show: true,
        description: 'The health page',
      },
    },
  },
  FEEDBACK: {
    name: 'feedback',
    url: ROUTE_URLS.GIVE_FEEDBACK,
    show: true,
    description: 'The give feedback page',
  },

  AUTH_REDIRECT: {
    name: 'auth redirect',
    url: 'auth-redirect',
    show: false,
    description: 'The auth redirect page used for azure logins only',
  },
  SITE_MAP: {
    name: 'sitemap',
    url: 'sitemap',
    show: false,
    description: 'The sitemap page - this page',
  },
};
