import { BOOL_LETTER_STRING } from '@ers/shared';

export interface BusinessCardResponse {
  status: string;
}

export interface ProductsInGroupRequest {
  actionType: string;
  groupNumber: string;
}

export type ProductsInGroupResponse = Product[];

export type SubmitFeedbackResponse = string;
export interface SubmitSafetyIncidentRequest {
  accidentLicense: string;
  accidentType: string;
  accidentTypeOther: string;
  correctiveAction: string;
  employeeDept: string;
  employeeMgr: string;
  employeeName: string;
  employeeTitle: string;
  incidentDate: string;
  incidentDetails: string;
  incidentLocation: string;
  incidentType: string;
  injuryCategory: string;
  injuryType: string;
  injuryTypeOther: string;
  location: string;
  photoIds: number[];
  recordName: string;
  reportedDate: string;
  reportedByEmail: string;
  rootCause: string;
  spillQty: string;
  spillType: string;
}

// export interface SubmitSafetyIncidentResponse {
//   status: string;
// }

export interface OffRentResponse {
  statusCode: number;
}

export interface ProductWorkOrder {
  ORDER_NUMBER: string;
  ORDER_DESC: string;
}

export interface Product {
  CLASS_N: string;
  CLASS_DESC: string;
  GROUP_N: string;
  GROUP_DESC: string;
  PRODUCT_N: string;
  PRODUCT_DESC: string;
  PRODUCT_MAKE?: string;
  PRODUCT_MODEL?: string;
  ON_HAND_LOC: string;
  ON_RENT: string;
  KEY_DATA?: string;
  COMP_CD: string;
  STATUS?: string;
  HAS_ORDERS: BOOL_LETTER_STRING.Y | BOOL_LETTER_STRING.N;
}

export const ON_RENT = {
  YES: 'YES',
  NO: 'NO',
  BULK: 'BULK',
  ALL: 'ALL', // no in the dataset but used in the UI
};

export const PRODUCT_TYPE_CONTRACT = {
  SALE: 'S',
};

export const PRODUCT_STATUS_CONTRACT = {
  RETURNED: 'R',
  ON_RENT: 'O',
  OFF_RENT: 'F',
};

export interface OnDemandDocsContractRequest {
  contractNumber: string;
}

export interface OnDemandDocsContractsRequest {
  repCode: string;
}

export interface OnDemandDocsContractSearchRequest {
  contractNumber: string | null;
  customerName: string | null;
  documentStatus: string | null;
  poNumber: string | null;
  productNumber: string | null;
  quoteNumber: string | null;
  repCode: string;
}

export interface OnDemandDocsInvoiceRequest {
  invoiceNumber: string;
}

export interface OnDemandDocsInvoicesRequest {
  repCode: string;
}

export interface OnDemandDocsInvoiceSearchRequest {
  contractNumber: string | null;
  customerName: string | null;
  documentStatus: string | null;
  invoiceNumber: string | null;
  poNumber: string | null;
  productNumber: string | null;
  quoteNumber: string | null;
  repCode: string;
}

export type OnDemandDocsPdf = {
  customerName: string;
  documentDate: string;
  documentNumber: string;
  documentType: string;
};

export interface OnDemandDocsPDFsRequest {
  documents: OnDemandDocsPdf[];
  userMail: string;
}

export interface OffRentRequest {
  contractNumber: string;
  userName: string;
  userEmail: string;
  offRentDate: string;
  pickupDate: string;
  pickupTime: string;
  contactName: string;
  contactPhone: string;
  pickupAddress: string;
  comments: string;
  techNeeded: boolean;
  contractLoc: string;
  customerName: string;
  spFolderId: string;
  quoteNumber: string;
  repEmail: string;
  items: {
    productDesc: string;
    productNumber: string;
    detailNumber: string;
    quantity: number;
  }[];
}
