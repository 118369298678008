import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '@ers-cat-app/env/environment';
import { LoginRequest } from '@ers/shared';
import { ROUTE_URLS, STORAGE_KEYS } from '@ers-cat-app/shared/constants';
import { Role } from '@ers/shared';
import { Router } from '@angular/router';
import { THEME } from '../system/system.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly BASE_URL = `${environment.apiUrl}/auth`;

  readonly isLoggedIn$ = new BehaviorSubject<boolean>(false);
  accessToken: string | null;
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private readonly http: HttpClient, // readonly msalService: MsalAuthService,
    private readonly router: Router,
  ) {
    this.accessToken =
      localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ||
      sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);

    if (
      (!this.accessToken || this.accessToken?.length === 0) &&
      this.isLoggedIn$.value
    ) {
      this.logout();
    }

    this.isLoggedIn$.next(!!this.accessToken);
  }

  get isDpsWorkOrderUser() {
    const isDpsWorkOrderUser = this.userHasRole(Role.DPS_WORK_ORDER);
    const userHasOnlyOneRole = this.getRoles().length === 1;

    return isDpsWorkOrderUser && userHasOnlyOneRole;
  }

  login(
    user: LoginRequest,
    isNormalLogin: boolean = true,
  ): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.BASE_URL}/login`, user).pipe(
      switchMap((response: LoginResponse): Observable<LoginResponse> => {
        if (isNormalLogin) {
          this.onLoginSuccess(response, true);
        }
        return of(response);
      }),
    );
  }

  async logout() {
    const selectedTheme = localStorage.getItem(STORAGE_KEYS.THEME);
    this.removeStorageKeys();
    const user = localStorage.getItem(STORAGE_KEYS.USER_EMAIL);

    // Clear local storage
    localStorage.clear();
    sessionStorage.clear();

    // Set theme again
    localStorage.setItem(STORAGE_KEYS.THEME, selectedTheme as THEME);

    // Set User Email (for Save User ID)
    if (user) {
      localStorage.setItem(STORAGE_KEYS.USER_EMAIL, user);
    }

    // set theme
    if (selectedTheme) {
      localStorage.setItem(STORAGE_KEYS.THEME, selectedTheme);
    }

    this.isLoggedIn$.next(false);
  }

  /** @deprecated Client requested to let azure handle the logout. */
  async logoutWithAzure() {
    const user = localStorage.getItem(STORAGE_KEYS.USER_EMAIL);
    const selectedTheme = localStorage.getItem(STORAGE_KEYS.THEME);

    localStorage.clear();
    sessionStorage.clear();
    if (user) {
      localStorage.setItem(STORAGE_KEYS.USER_EMAIL, user);
    }
    // await this.msalService.logout();

    if (selectedTheme) {
      localStorage.setItem(STORAGE_KEYS.THEME, selectedTheme);
    }
  }

  handleRedirectAfterLogin() {
    if (this.isDpsWorkOrderUser) {
      this.router.navigate([ROUTE_URLS.DPS_WORK_ORDER]);
    } else {
      this.router.navigate([ROUTE_URLS.HOME]);
    }
  }

  removeStorageKeys() {
    const localStorageKeys = [
      STORAGE_KEYS.EQUIPMENT_CACHE,
      STORAGE_KEYS.LOGGED_IN,
      STORAGE_KEYS.TOKEN_EXPIRATION,
      STORAGE_KEYS.ACCESS_TOKEN,
      STORAGE_KEYS.STATIC_VARS_CACHE,
    ];
    const sessionStorageKeys = [
      STORAGE_KEYS.ACCESS_TOKEN,
      STORAGE_KEYS.LOGGED_IN,
      STORAGE_KEYS.TOKEN_EXPIRATION,
    ];

    sessionStorageKeys.forEach(key => {
      sessionStorage.removeItem(key);
    });

    localStorageKeys.forEach(key => {
      localStorage.removeItem(key);
    });
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/forgot-password`, {
      email: email,
    });
  }

  onLoginSuccess(response: { accessToken: string }, rememberMe: boolean) {
    this.accessToken = response.accessToken;
    const decodedUser = this.jwtHelper.decodeToken(response.accessToken);

    if (rememberMe) {
      localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.accessToken);
      localStorage.setItem(STORAGE_KEYS.LOGGED_IN, 'true');
      localStorage.setItem(STORAGE_KEYS.TOKEN_EXPIRATION, decodedUser.exp);
    } else {
      sessionStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.accessToken);
      sessionStorage.setItem(STORAGE_KEYS.LOGGED_IN, 'true');
      sessionStorage.setItem(STORAGE_KEYS.TOKEN_EXPIRATION, decodedUser.exp);
    }

    this.isLoggedIn$.next(true);

    this.handleRedirectAfterLogin();
  }

  resetPassword(code: string, password: string) {
    return this.http.post<boolean>(`${this.BASE_URL}/reset-password`, {
      code,
      password,
    });
  }

  // By default, use current logged in user's info
  isAzureUser(accessToken = this.accessToken): boolean {
    try {
      if (!accessToken) {
        return false;
      }

      return this.jwtHelper.decodeToken(accessToken).azureIdExists;
    } catch (err) {
      return false;
    }
  }

  // By default, use current logged in user's info
  getRoles(accessToken = this.accessToken): Role[] {
    try {
      if (!accessToken) {
        return [];
      }

      return (this.jwtHelper.decodeToken(accessToken).roles as Role[]) || [];
    } catch (err) {
      return [];
    }
  }

  // By default, use current logged in user's info
  userHasRole(role: Role, accessToken = this.accessToken): boolean {
    const currentUserRoles = this.getRoles(accessToken).map(currRole =>
      currRole?.toUpperCase(),
    );
    return currentUserRoles.includes(role);
  }

  // By default, use current logged in user's info
  userHasRoleGivenListOfRoles(
    authorizedRoles: Role[],
    accessToken = this.accessToken,
  ): boolean {
    const currentUserRoles = this.getRoles(accessToken).map(role =>
      role.toUpperCase(),
    );
    return authorizedRoles.some((authorizedRole: Role) =>
      currentUserRoles.includes(authorizedRole),
    );
  }

  hasSalesHubAccess() {
    return this.userHasRoleGivenListOfRoles([
      Role.SALES_REP,
      Role.EXECUTIVE,
      Role.ADMIN,
      Role.MANAGER,
      Role.SALES_HUB,
      Role.SALES_HUB_ADMIN,
      Role.SALES_HUB_MANAGER,
    ]);
  }

  hasAdminSectionAccess() {
    return this.userHasRoleGivenListOfRoles([
      Role.ADMIN,
      Role.EXECUTIVE,
      Role.ADMIN_ROLES,
      Role.ADMIN_SYSTEMS,
      Role.ADMIN_USERS,
      Role.ADMIN_USERS_READ_ONLY,
    ]);
  }

  hasToolsSectionAccess() {
    return this.userHasRoleGivenListOfRoles([
      Role.CALCULATORS,
      Role.ON_DEMAND_DOCS,
      Role.OFF_RENT_REQUEST,
      Role.SAFETY_INCIDENT_REPORT,
      Role.UPLIFT,
      Role.HOUR_METER,
    ]);
  }

  hasAssetTrackingAccess() {
    return this.userHasRole(Role.ADMIN);
  }

  hasJobMonitoringAccess() {
    return this.userHasRole(Role.ADMIN);
  }

  hasNewsAccess() {
    return this.userHasRole(Role.NEWS);
  }

  // isAzureLoggedIn() {
  //   return this.msalService.isLoggedIn();
  // }
}

export type LoginResponse = { accessToken: string };
