import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@ers-cat-app/env/environment';
import { GetHierarchyDto } from '@ers/shared';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private readonly BASE_URL = `${environment.apiUrl}/category`;

  constructor(private readonly http: HttpClient) {}

  list(): Observable<GetHierarchyDto[]> {
    return this.http.get<GetHierarchyDto[]>(this.BASE_URL);
  }
}
