import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '@ers-cat-app/env/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureClientId,
      authority: `https://login.microsoftonline.com/${environment.azureTenantId}`,
      redirectUri: window.location.origin + '/auth-redirect',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal.
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      // loggerOptions: {
      //   loggerCallback,
      //   logLevel: LogLevel.Info,
      //   piiLoggingEnabled: false,
      // },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    [
      '/api/auth/loginWithAzure',
      [`api://${environment.azureClientId}/user.access`],
    ],
    ['/api/auth/loginWithAzure', ['user.read']],
  ]);

  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']); // PPE testing environment

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
