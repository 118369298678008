// This component is part of @azure/msal-angular and can be imported and bootstrapped
import { Component, OnInit } from '@angular/core';
import { MsalAuthService } from '@ers-cat-app/shared/services';

import { take } from 'rxjs';

/**
 * global MSAL redirect listener
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-redirect', // Selector to be added to index.html
  template: '',
  standalone: true,
})
export class RedirectComponent implements OnInit {
  constructor(private msalAuthService: MsalAuthService) {}

  ngOnInit(): void {
    this.msalAuthService.msalService
      .handleRedirectObservable()
      .pipe(take(1))
      .subscribe(async () => {
        // this.msalAuthService.checkAndSetActiveAccount();
        // this fires too quickly to do what we want, and also doesn't fire on sso
        // this.authService.msalService.postLoginWithAzure();
      });
  }

  // IonViewWillEnter(): void {
  //   this.msalAuthService.msalService
  //     .handleRedirectObservable()
  //     .pipe(take(1))
  //     .subscribe(async () => {
  //       // this.msalAuthService.checkAndSetActiveAccount();
  //       // this fires too quickly to do what we want, and also doesn't fire on sso
  //       // this.authService.msalService.postLoginWithAzure();
  //     });
  // }
}
