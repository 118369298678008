import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { firstValueFrom, noop, take, throwError, EMPTY } from 'rxjs';
import { environment } from '@ers-cat-app/env/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly VAPID_PUBLIC_KEY = "BFc4chh7Qc5T04g-EAJyk5XYUp_oT0jYFFJ5UZm_3GMbuANNMRP5wNSN9a3kcfVJc2ThyLo9r__puKg-BxrSfe0";
  private readonly BASE_URL = `${environment.apiUrl}/notifications`;
  private readonly headers = new HttpHeaders({
    "ngsw-bypass": "",
    "Content-Type": "application/json",
  });

  constructor(
    private readonly http: HttpClient,
    private readonly swPush: SwPush
  ) {
    this.swPush.subscription
    .subscribe(subscription => {
      if (subscription) {
        this.isSubscribed = true;
      }
    })
  }

  isSubscribed = false;

  isEnabled = this.swPush.isEnabled;
  isSubscribed$ = this.swPush.subscription;

  /**
   * Request a push notification subscription
   * @returns The push notification subscription
   */
  async requestSubscription() {
    if (!this.isEnabled) {
      return throwError(() => new Error("Notifications are not enabled in this browser."));
    }

    if (this.isSubscribed) {
      return EMPTY;
    }

    try {
      const subscription = await this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      });
      /// TODO: what to do if the request fails, the subscription will still exist in the browser but not the server
      const headers = new HttpHeaders({ "ngsw-bypass": "", });
      return this.http.post<PushSubscription>(`${this.BASE_URL}/subscribe`, JSON.stringify(subscription), { headers: this.headers });
    }
    catch(err) {
      console.error(err);
      return throwError(() => err);
    }
  }

  async unsubscribe() {
    try {
      const subscription$ = this.swPush.subscription.pipe(take(1));
      const subscription = await firstValueFrom(subscription$, { defaultValue: null });
      if (subscription) {
        const payload = JSON.stringify(subscription);
        await subscription.unsubscribe();
        /// TODO: what to do if the request fails, the subscription will still exist in the server
        const headers = new HttpHeaders({ "ngsw-bypass": "", });
        return this.http.post<PushSubscription>(`${this.BASE_URL}/unsubscribe`, payload, { headers: this.headers });
      }
      return EMPTY;
    }
    catch(err) {
      console.error(err);
      return throwError(() => err);
    }
  }
}
