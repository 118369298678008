import { Injectable } from '@angular/core';
import { ASSET_LOCATIONS, ROUTE_URLS } from '../../constants';
import { AuthService } from '../auth/auth.service';
import { Params } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { DomainService } from '../domain/domain.service';
import { CompanyDomainEnum } from '../domain';

export interface MenuItem {
  title: string;
  url: string;
  show: boolean;
  icon: string; // required; used as a fallback if iconLocation is not provided
  iconLocation?: string;
  args?: Params;
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuItems: MenuItem[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly domainService: DomainService,
  ) {
    combineLatest([
      this.authService.isLoggedIn$,
      this.domainService.currentDisplayedDomainChanged$,
    ])
      .pipe(
        map(([isLoggedIn, currentDomain]) => ({ isLoggedIn, currentDomain })),
      )
      .subscribe(({ isLoggedIn, currentDomain }) => {
        // console.log('User is logged in:', isLoggedIn);
        // console.log('Current displayed domain:', currentDomain);
        if (isLoggedIn) {
          this.setMenuItems();
        } else {
          this.menuItems = [];
        }
      });
  }

  setMenuItems() {
    this.menuItems = [
      { title: 'Home', url: ROUTE_URLS.HOME, show: true, icon: 'home' },
      {
        title: 'Safety',
        url: ROUTE_URLS.SAFETY,
        show: true,
        icon: 'shield',
        iconLocation: ASSET_LOCATIONS.SAFETY_HELMET_SVG,
      },
      {
        title: 'Sales Hub',
        url: ROUTE_URLS.SALES_HUB,
        show: this.authService.hasSalesHubAccess(),
        icon: 'bar-chart',
      },
      {
        title: 'Equipment',
        url: ROUTE_URLS.EQUIPMENT,
        show: true,
        icon: 'newspaper',
      },
      {
        title: 'Job Monitoring',
        url: ROUTE_URLS.JOB_MONITORING,
        show:
          this.authService.hasJobMonitoringAccess() &&
          this.domainService.currentDisplayedDomainValue ===
            CompanyDomainEnum.DPS,
        icon: 'speedometer',
      },
      {
        title: 'Asset Tracking',
        url: ROUTE_URLS.ASSET_TRACKING,
        show:
          this.authService.hasAssetTrackingAccess() &&
          this.domainService.currentDisplayedDomainValue ===
            CompanyDomainEnum.DPS,
        icon: 'location',
      },
      {
        title: 'Documents',
        url: ROUTE_URLS.DOCUMENTS,
        show: true,
        icon: 'documents',
      },
      {
        title: 'Tools',
        url: ROUTE_URLS.TOOLS,
        show: this.authService.hasToolsSectionAccess(),
        icon: 'build',
      },
      {
        title: 'Training',
        url: ROUTE_URLS.DOCUMENT_PAGE,
        show: true,
        icon: 'clipboard',
        args: { path: 'PRS/Training' } as Params,
      },
      {
        title: 'Administration',
        url: ROUTE_URLS.ADMIN,
        show: this.authService.hasAdminSectionAccess(),
        icon: 'settings',
      },
      {
        title: 'Site Map',
        url: ROUTE_URLS.SITE_MAP,
        show: true,
        icon: 'map',
      },
    ];
  }

  get menu(): MenuItem[] {
    return this.menuItems;
  }

  menuWithoutItems(excludedItems: string[]) {
    return this.menuItems.filter(
      (item: MenuItem) =>
        !excludedItems
          .map(excluded => excluded.toLowerCase())
          .includes(item.title.toLowerCase()),
    );
  }
}
