export class QueryParams {
  productNumber?: string | null;
  customerName?: string | null;
  docNumber?: string | null;
  poNumber?: string | null;
  dynamicFilter?: string | null;
  docStatus?: string | null;
}

export class Document {
  customer_name?: string | null;
  doc_date?: string | null;
  doc_number?: string | null;
  doc_type?: DocumentType | null;
}

export type ActionType =
  | 'documentsGet'
  | 'repListGet'
  | 'oarListGet'
  | 'oarGet'
  | 'conListGet'
  | 'conGet';

export enum DocumentType {
  INVOICE = 'invoice',
  CONTRACT = 'contract',
}

export enum DocumentStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ANY = 'ANY',
}
