export const enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SALES_REP = 'SALES_REP',
  GUEST = 'GUEST',
  MANAGER = 'MANAGER',
  EXECUTIVE = 'EXECUTIVE',
  EXTERNAL = 'EXTERNAL',
  OPERATIONS = 'OPERATIONS',

  // feature roles
  NEWS = 'NEWS',
  DPS_WORK_ORDER = 'DPS_WO',

  // sales hub roles
  SALES_HUB = 'SALES_HUB', // sales hub user aka sales rep
  SALES_HUB_MANAGER = 'SALES_HUB_MANAGER', // sales rep manager
  SALES_HUB_ADMIN = 'SALES_HUB_ADMIN', // sales hub admin

  // equipment roles
  EQUIPMENT_RATES = 'EQUIPMENT_RATES', // allows for the equipment rates view
  EQUIPMENT_ADMIN = 'EQUIPMENT_ADMIN', // allows for the equipment admin view
  EQUIPMENT_REPLACEMENT = 'EQUIPMENT_REPLACEMENT', // allows for the equipment replacement value modification

  // document roles
  DOCUMENTS = 'DOCUMENTS', // allows for the documents view

  // tool roles
  CALCULATORS = 'CALCULATORS', // allows for the tools view
  ON_DEMAND_DOCS = 'ON_DEMAND_DOCS', // allows for the on demand docs view
  SAFETY_INCIDENT_REPORT = 'SAFETY_INCIDENT_REPORT', // allows for the safety incident report view
  OFF_RENT_REQUEST = 'OFF_RENT_REQUEST', // allows for the off rent request view
  UPLIFT = 'UPLIFT', // allows for the uplift view
  PICK_ORDER = 'PICK_ORDER', // allows for the pick order view
  HOUR_METER = 'HOUR_METER', // allows for the hour meter view


  // administration roles
  ADMIN_USERS = 'ADMIN_USERS', // allows for the administration user view
  ADMIN_USERS_READ_ONLY = 'ADMIN_USERS_READ_ONLY', // allows for the administration user view
  ADMIN_ROLES = 'ADMIN_ROLES', // allows for the administration Roles view
  ADMIN_SYSTEMS = 'ADMIN_SYSTEMS', // allows for the administration user view

  SWITCH_DOMAINS = 'SWITCH_DOMAINS',
}
