export interface CalcCoolingInput {
  wallMaterial: string;
  roofMaterial: string;
  structureLength: number;
  structureWidth: number;
  wallHeight: number;
  peakHeight: number;
  ambientTemperature: number;
  desiredInternalTemperature: number;
  dayOrNight: string;
  numberOfPeople: number;
  peopleCode: string;
  wattageOfLighting: number;
  wallCalculation: number;
  ceilingCalculation: number;
  additionalLoad: number;
  totalBtu: number;
  reqTonnageReturnAir: number;
  reqTonnageOutsideAir: number;
  customerName?: string;
  projectName?: string;
  contactName?: string;
  salesRepresentative?: string;
  phoneNumber?: string;
  notes?: string;
}
