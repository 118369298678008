import { Injectable, OnDestroy } from '@angular/core';

/**
 * Generic service used to help pass data between page navigation
 */
@Injectable({
  providedIn: 'root',
})
export class NavParamsService implements OnDestroy {
  private data: Map<string, any> = new Map<string, any>();

  constructor() {}

  set(param: string, data: any) {
    this.data.set(param, data);
  }

  get(param: string) {
    if (this.data.has(param)) {
      return this.data.get(param);
    }
  }

  delete(param: string) {
    if (this.data.has(param)) {
      this.data.delete(param);
    }
  }

  ngOnDestroy() {
    this.data.clear();
  }
}
