import { Inject, Injectable, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { environment } from '@ers-cat-app/env/environment';
import { CompanyDomain, CompanyDomainEnum } from './company-domain';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AuthService } from '../auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { COMPANY } from '@ers/shared';

/**
 * Detect domain
 */
@Injectable({
  providedIn: 'root',
})
export class DomainService implements OnDestroy {
  private readonly authSubscription: Subscription;
  private readonly _currentDisplayedDomain$ =
    new BehaviorSubject<CompanyDomain>(CompanyDomainEnum.ERS);

  // Exposing as Observable to prevent .next() from outside
  readonly currentDisplayedDomainChanged$: Observable<CompanyDomain> =
    this._currentDisplayedDomain$.asObservable();

  // Getter for the currentDisplayedDomain$ to avoid direct access to the BehaviorSubject i.e. calling .next() on it
  get currentDisplayedDomainValue(): CompanyDomain {
    return this._currentDisplayedDomain$.getValue();
  }

  get currentDisplayedDomainCompanyCode() {
    return this.currentDisplayedDomainValue === CompanyDomainEnum.ERS
      ? COMPANY.ERS.CODE
      : COMPANY.DPS.CODE;
  }

  get currentDomain() {
    return this.document.location.origin;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly authService: AuthService,
  ) {
    // reset every time user logs in or out
    this.authSubscription = this.authService.isLoggedIn$.subscribe(() => {
      const value = this.isErsDomain()
        ? CompanyDomainEnum.ERS
        : CompanyDomainEnum.DPS;
      this._currentDisplayedDomain$.next(value);
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  isErsDomain() {
    const ersDomainMatchString = environment.ersDomainMatchString;
    return new RegExp(ersDomainMatchString).test(this.currentDomain);
  }

  toggleDomain() {
    const newValue =
      this.currentDisplayedDomainValue === CompanyDomainEnum.ERS
        ? CompanyDomainEnum.DPS
        : CompanyDomainEnum.ERS;

    this._currentDisplayedDomain$.next(newValue);
  }
}
