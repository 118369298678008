import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, lastValueFrom, of, retry } from 'rxjs';

import { environment } from '@ers-cat-app/env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from '@prisma/client';
import { Role } from '@prisma/client';
import {
  AdManager,
  AdUserWithDepartment,
  AdUserWithManager,
  UserWithRoles,
} from '@ers/shared';
import { STORAGE_KEYS } from '@ers-cat-app/shared/constants';
import { AuthService } from '../auth/auth.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly BASE_URL = `${environment.apiUrl}/user`;
  private _user$: UserWithRoles | any;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) {
    this.authService.isLoggedIn$
      .pipe(untilDestroyed(this))
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          // call profile() and store the result in user$ instead of calling profile() directly each time we need the user's info
          this.user$ = lastValueFrom(
            this.profile().pipe(
              untilDestroyed(this),
              retry({ count: 2, delay: 3000 }),
            ),
          );
        } else {
          this.user$ = undefined;
        }
      });
  }

   get user$(): UserWithRoles | any {
    return this._user$;
  }

  private set user$(user: UserWithRoles | any) {
    this._user$ = user;
  }

  list(): Observable<User[]> {
    return this.http.get<User[]>(this.BASE_URL);
  }

  get(id: number): Observable<UserWithRoles> {
    return this.http.get<UserWithRoles>(`${this.BASE_URL}/${id}`);
  }

  update(user: Partial<User>): Observable<Partial<User>> {
    return this.http.patch(`${this.BASE_URL}`, user);
  }

  updateUserWithRoles(
    user: Partial<User>,
    newRoles: Role[],
  ): Observable<Partial<User>> {
    return this.http.post(`${this.BASE_URL}/userWithRoles`, {
      user,
      roles: newRoles,
    });
  }

  delete(id: number): Observable<Partial<User>> {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  profilePicture(): Observable<Blob | null> {
    if (localStorage.getItem(STORAGE_KEYS.AZURE_LOGIN)) {
      return this.http.get<Blob>(`${this.BASE_URL}/profile/picture`, {
        responseType: 'blob' as 'json',
      });
    }
    return of(null);
  }

  getProfilePicture(email: string): Observable<Blob | null> {
    return this.http.get<Blob>(`${this.BASE_URL}/profile/picture/${email}`, {
      responseType: 'blob' as 'json',
    });
  }

  getAdUsers(): Observable<AdUserWithDepartment[]> {
    return this.http.get<AdUserWithDepartment[]>(
      `${this.BASE_URL}/ad/safety-report`,
    );
  }

  getAdUsersManager(userId: string): Observable<AdManager> {
    return this.http.get<AdUserWithManager>(
      `${this.BASE_URL}/ad/safety-report/manager/${userId}`,
    );
  }

  private profile(): Observable<UserWithRoles> {
    return this.http.get<UserWithRoles>(`${this.BASE_URL}/profile`);
  }
}
